/* eslint-disable indent */
import React from "react";

import c from "classnames";

import { Voucher } from "@/types/pet";
import { track } from "@/helpers/analytics";

import Styles from "./priceAndDiscount.module.scss";

import { Select } from "../Select";

type Props = {
	price: number;
	voucher?: Voucher | null;
	account?: boolean;
	discount?: number;
	deleted?: boolean;
	perDay?: boolean;
	voucherTag?: boolean;
	color?: "primary" | "secondary" | "normal" | "plain";
	children?: React.ReactNode;
	extras?: boolean;
	dontDiscount?: number;
	centerSelf?: boolean;
	deletedPerDay?: boolean;
	restart?: boolean;
	originalPrice?: number;
	className?: string;
	twoLines?: boolean;
	frequency?: number;
	period?: string;
	setPeriod?: (val: string) => void;
};

export const PriceAndDiscount = ({
	price,
	voucher,
	account = false,
	discount,
	deleted = true,
	perDay = false,
	voucherTag = true,
	color = "normal",
	extras = false,
	dontDiscount = 0,
	centerSelf = false,
	children,
	deletedPerDay = false,
	restart = false,
	originalPrice,
	className,
	twoLines = false,
	frequency,
	period,
	setPeriod,
}: Props) => {
	if (
		(extras && voucher?.only_apply_to_primary_items) ||
		(!extras && voucher?.only_apply_to_secondary_items)
	) {
		voucher = {
			...voucher,
			amount: 0,
		};
		discount = 0;
	}

	if (frequency && period === "day") {
		price = price / frequency;
	}

	if (frequency && period === "week") {
		price = (price / frequency) * 7;
	}

	if (frequency && period === "month") {
		price = (price / frequency) * 28;
	}

	const Wrapper = ({ children }: { children: React.ReactNode }) => {
		return (
			<div
				className={c(Styles.priceAndDiscount, "priceAndDiscountComponent", {
					[Styles.centerSelf]: centerSelf,
					[Styles[color + "Color"]]: color !== "normal",
					[Styles.twoLines]: deletedPerDay || twoLines,
					[className || ""]: !!className,
				})}
			>
				{children}
			</div>
		);
	};

	const displayPrice = account ? Math.max(0, price / 100) : Math.max(0, price);

	if (discount && discount > 0) {
		discount = discount / 100;
		const discountShow = Number.isInteger(discount / 10)
			? "£" + discount
			: Math.round((discount / displayPrice) * 100) + "%";
		const content = (
			<>
				{displayPrice - discount === 0
					? "Free"
					: "£" + Math.max(0, displayPrice - discount).toFixed(2)}

				{perDay && <span className={Styles.perDay}>/day</span>}
			</>
		);

		return (
			<Wrapper>
				{deleted && (
					<del>
						£
						{originalPrice && originalPrice > displayPrice
							? originalPrice.toFixed(2)
							: displayPrice.toFixed(2)}
					</del>
				)}
				{content}
				{discount > 0 && voucherTag && (
					<span className={Styles.voucherTag}>{discountShow} off</span>
				)}{" "}
				{children}
			</Wrapper>
		);
	} else if (
		voucher &&
		(voucher.type_limit === "subscription" ||
			(voucher.type_limit === "non_transition" && restart) ||
			voucher.type_limit === "all") &&
		voucher.amount > 0
	) {
		const discountTransitionPrice =
			voucher.type === "fixed"
				? Math.max(displayPrice - voucher.amount / 100, 0).toFixed(2)
				: Math.max(
						0,
						displayPrice -
							(displayPrice - dontDiscount) * (voucher.amount / 100)
					).toFixed(2);

		const discountVoucher =
			voucher.type === "fixed"
				? "£" + (voucher.amount / 100).toFixed(2) + " off"
				: voucher.amount + "% off";
		// SET PRICE WITH VOUCHER

		return (
			<Wrapper>
				{deleted && price && (
					<del>
						£
						{originalPrice && originalPrice > displayPrice
							? originalPrice
							: displayPrice.toFixed(2)}
						{deletedPerDay && !frequency && " /day"}
					</del>
				)}
				£{discountTransitionPrice}
				{frequency ? (
					<>
						{" "}
						<span className={Styles.per}>per</span>
						<Select
							name="frequency"
							options={[
								{
									label: "day",
									value: "day",
								},
								{
									label: "week",
									value: "week",
								},
								{
									label: "month",
									value: "month",
								},
								{
									label: "box",
									value: "box",
								},
							]}
							value={period}
							inline
							sizing="tiny"
							onChange={(newVal) => {
								setPeriod && setPeriod(newVal);
								track("Price Change Period", {
									newPeriod: newVal,
								});
							}}
						/>
					</>
				) : (
					perDay && <span className={Styles.perDay}>/day</span>
				)}
				{voucherTag && (
					<span className={Styles.voucherTag}>{discountVoucher}</span>
				)}{" "}
				{children}
			</Wrapper>
		);
	} else {
		const content = (
			<>
				{deleted && originalPrice && originalPrice > displayPrice && (
					<del>£{originalPrice}</del>
				)}

				<>£{displayPrice?.toFixed(2)}</>

				{perDay && <span className={Styles.perDay}>/day</span>}
			</>
		);

		return (
			<Wrapper>
				{content}

				{children}
			</Wrapper>
		);
	}
};
