import { usePriceSummary } from "./usePriceSummary";
import Styles from "./priceSummary.module.scss";

type Props = {
	showDelivery?: boolean;
};

export const PriceSummary = ({ showDelivery = true }: Props) => {
	const { checkout } = usePriceSummary();

	return checkout?.pricing ? (
		<div className={Styles.priceSummary}>
			{checkout.pricing.discount > 0 && (
				<>
					<div className={Styles.discount}>Discounts</div>
					<div className={Styles.discountPrice}>
						-£{(checkout.pricing.discount / 100).toFixed(2)}
					</div>
				</>
			)}

			{showDelivery ? (
				<>
					<div>Delivery</div>
					<div>
						{checkout.pricing.delivery && checkout.pricing.delivery > 0 ? (
							<>£{(checkout.pricing.delivery / 100).toFixed(2)}</>
						) : (
							<>Free</>
						)}
					</div>
					<div className={Styles.total}>Total</div>
					<div className={Styles.total}>
						£{(checkout.pricing.subtotal / 100).toFixed(2)}
					</div>
				</>
			) : (
				<>
					<div>Subtotal</div>
					<div className={Styles.total}>
						£{(checkout.pricing.subtotal / 100).toFixed(2)}
					</div>
				</>
			)}
		</div>
	) : (
		<></>
	);
};
