import { useEffect, useState } from "react";

import Link from "next/link";
import c from "classnames";

import { useUserDataStore } from "@/store/UserDataStore";
import { Voucher } from "@/types/pet";

import Styles from "./offerBanner.module.scss";

type Props = {
	type?: string;
	ongoingOnly?: boolean;
	noLink?: boolean;
	overVoucher?: Voucher;
	fadeIn?: boolean;
};

export const OfferBanner = ({
	type = "hero",
	ongoingOnly = false,
	noLink = false,
	overVoucher,
	fadeIn = true,
}: Props) => {
	const [user] = useUserDataStore((state) => [state.user]);

	const [voucher, setVoucher] = useState<Voucher | null>(user?.voucher || null);

	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (isLoaded) {
			return;
		}

		setTimeout(() => {
			if ((user?.voucher?.code === voucher?.code && user.voucher) || voucher) {
				setIsLoaded(true);
			}
		}, 1);
	}, [isLoaded, user.voucher, voucher]);

	useEffect(() => {
		if (user) {
			if (overVoucher) {
				setVoucher({ future_discounts: [overVoucher] });
				return;
			}
			if (!voucher || voucher.code !== user.voucher?.code) {
				setVoucher(user.voucher || null);
				return;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const Content = (
		<>
			{!ongoingOnly && (
				<>
					<span className={Styles.bannerNumber}>
						{voucher?.type === "fixed"
							? "£" + (voucher.amount / 100).toFixed(0)
							: voucher?.amount + "%"}
					</span>{" "}
					{voucher?.type_limit === "non_transition" ? (
						"off when you restart your plan"
					) : (
						<>
							off your first{" "}
							{(parseInt(voucher?.set_limit_amount || "") || 0) > 1 &&
								voucher?.set_limit_amount}{" "}
							box
							{(parseInt(voucher?.set_limit_amount || "") || 0) > 1 && "es"}
						</>
					)}
				</>
			)}
			{voucher?.future_discounts?.map((discount, i) => (
				<div className={Styles.secondDiscount} key={i}>
					{!ongoingOnly && <>+ </>}
					<span className={Styles.bannerNumber}>
						{discount?.type === "fixed"
							? "£" + (discount.amount / 100).toFixed(0)
							: discount?.amount + "%"}
					</span>{" "}
					off{" "}
					{(discount.set_limit_amount
						? parseInt(discount.set_limit_amount)
						: 0) < 2 ? (
						<>your next</>
					) : parseInt(discount.set_limit_amount ?? "0") > 100 ? (
						<>for life</>
					) : (
						<>your next {discount.set_limit_amount}</>
					)}
					{ongoingOnly && parseInt(discount.set_limit_amount ?? "0") < 100 && (
						<>
							{(parseInt(discount.set_limit_amount || "") || 0) < 2 ? (
								<> box</>
							) : (
								<> boxes</>
							)}
						</>
					)}
				</div>
			))}
		</>
	);

	if (
		voucher?.future_discounts?.[0]?.only_apply_to_secondary_items &&
		ongoingOnly
	) {
		return <></>;
	}
	return (
		<section
			className={c(Styles.offerBanner, {
				[Styles.inHeader]: type === "header",
			})}
		>
			<div
				className={c({
					"is-to-load-in": fadeIn ? true : false,
					"is-loaded": fadeIn ? isLoaded : true,
				})}
			>
				{noLink ? (
					<div className={c("h4", Styles.content)}>{Content}</div>
				) : (
					<Link href="/signup">
						<a className={c("h4", Styles.content)}>{Content}</a>
					</Link>
				)}
			</div>
		</section>
	);
};
