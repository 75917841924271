export type FieldValidators = {
	pattern?: RegExp;
	errorMessages?: {
		required?: string;
		invalid?: string;
	};
};

export const emailValidator: FieldValidators = {
	pattern: /^\S+@\S+\.\S+$/,
	errorMessages: {
		invalid: "Please enter a valid email address",
		required: "Please enter an email address",
	},
};
export const phoneValidator: FieldValidators = {
	pattern: /^\+44\s?\d{10}$|^\(?0\d{9,10}$/,
	errorMessages: {
		invalid: "Please enter a valid contact number",
		required: "Please enter your contact number",
	},
};
